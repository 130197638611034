var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"sc-chat-window",attrs:{"border":""}},[_c('v-row',{staticClass:"d-flex flex-row justify-end mt-2 px-3"},[_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-left")]),_vm._v(" Voltar ")],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12"}},[_c('p')])],1),_c('div',{ref:"scrollList",staticClass:"sc-message-list",staticStyle:{"background":"#f2f2f2"},attrs:{"color":"#0B1138"}},[_c('div',{staticClass:"chats"},[[_c('pre',{staticStyle:{"color":"#000"}},[_vm._v("                  "+_vm._s(_vm.API_URL)+" \n                ")]),_vm._l((_vm.messages),function(message,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[(message.author.id !== _vm.currentUser.id)?_c('v-avatar',{attrs:{"size":"55"}},[_c('v-img',{attrs:{"src":message.author.photo != null
                            ? `${_vm.apiUrl}/images/user/${message.author.photo}`
                            : '/profile.png'}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',{staticClass:"time"},[(message.author.id !== _vm.currentUser.id)?_c('span',{staticClass:"message-time-in",staticStyle:{"margin-left":"0%","font-size":"12pt"}},[_vm._v(_vm._s(message.author.name))]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index != _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_c('span',{staticStyle:{"margin-left":"80%","font-size":"12pt"}},[_vm._v(" Eu")])]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index === _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_c('span',{staticStyle:{"margin-left":"80%","font-size":"12pt"}},[_vm._v(" Eu")])]):_vm._e()]),_c('p',{staticClass:"message",class:{
                        'message-out': message.author.id === _vm.currentUser.id,
                        'message-in': message.author.id !== _vm.currentUser.id,
                      }},[_vm._v(" "+_vm._s(message.body)+" ")]),_c('v-row',{staticClass:"time"},[(message.author.id !== _vm.currentUser.id)?_c('span',{staticClass:"message-time-out"},[_vm._v(_vm._s(message.time))]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index != _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_vm._v(_vm._s(message.time)+" ")]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index === _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_vm._v(_vm._s(_vm.sendFlag ? message.time : "Enviando...")+" ")]):_vm._e()])],1)],1)],1)],1)],1)})]],2)]),_c('v-container',{staticStyle:{"background":"#f2f2f2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.messages.length > 0)?_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icon,"solo":"","clear-icon":"mdi-send","clearable":"","label":"Type your message here","type":"text"},on:{"click:prepend":_vm.changeIcon,"click:append-outer":_vm.sendMessage,"click:clear":_vm.sendMessage},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }