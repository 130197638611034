var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"sc-chat-window",attrs:{"border":""}},[_vm._l((6),function(userSkeleton,index){return [(_vm.chats.length === 0)?_c('v-skeleton-loader',{key:index,attrs:{"type":"list-item-avatar"}}):_vm._e()]}),_c('v-list',{staticClass:"py-3",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.chats),function(user,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.initChat(user.messages[0].author)}}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":`${
                    user.messages[0].author.photo  != null
                      ? _vm.apiUrl + user.messages[0].author.photo
                      : '/profile.png'
                  }`,"contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.messages[0].author.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(user.messages[user.messages.length - 1].body))])],1),_c('v-list-item-action',[_c('v-list-item-icon',[(_vm.countReads(user.messages[0].author.id))?_c('v-badge',{attrs:{"content":_vm.newMessage
                      ? _vm.countReads(user.messages[0].author.id) - 1
                      : _vm.countReads(user.messages[0].author.id),"color":"red"}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-bell ")])],1):_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-bell ")])],1)],1)],1)}),1)],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.chat)?_c('suportChat',{attrs:{"users":_vm.user},on:{"back":_vm.close}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }