<template>
  <v-row no-gutters>
    <v-col cols="12" md="4">
      <v-card class="sc-chat-window" border>
        <!-- <pre>
          {{chats}}
        </pre> -->
        <template v-for="(userSkeleton, index) in 6">
          <v-skeleton-loader
            :key="index"
            v-if="chats.length === 0"
            type="list-item-avatar"
          >
          </v-skeleton-loader>
        </template>
        <v-list dense class="py-3">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(user, index) in chats"
              :key="index"
              @click="initChat(user.messages[0].author)"
            >
              <v-list-item-avatar size="30">
                <v-img
                  :src="
                    `${
                      user.messages[0].author.photo  != null
                        ? apiUrl + user.messages[0].author.photo
                        : '/profile.png'
                    }`
                  "
                  contain
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  user.messages[0].author.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.messages[user.messages.length - 1].body
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-badge
                    v-if="countReads(user.messages[0].author.id)"
                    :content="
                      newMessage
                        ? countReads(user.messages[0].author.id) - 1
                        : countReads(user.messages[0].author.id)
                    "
                    color="red"
                  >
                    <v-icon medium>
                      mdi-bell
                    </v-icon>
                  </v-badge>
                  <v-icon medium v-else>
                    mdi-bell
                  </v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <suportChat v-if="chat" :users="user" @back="close"></suportChat>
    </v-col>
  </v-row>
</template>

<script>
import suportChat from "./suportChat";
import { USERS } from "./../../graphql/Query";
import { mapGetters } from "vuex";
import { API_URL_KENMARE } from "@/api";
import { CHATS_ROOM_QUERY } from "./../../graphql/Query";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../../graphql/subscription";
import { CHATS_ROOM_QUERY_KEN } from '../../graphql/kenmare/Query';
export default {
  name: "Chat",
  components: {
    suportChat,
  },
  data() {
    return {
      apiUrl: API_URL_KENMARE + "/images/user/",
      user: [],
      AllUsers: [],
      chat: false,
      chats_: [],
      chats: [],
      newMessage: false,
      flag: 1,
    };
  },
  apollo: {
    users: {
      query: USERS,
      fetchPolicy: "no-cache",
      result({ data }) {
        console.log("users", data);
        data.users.forEach((element) => {
          if (
            (element.id != this.currentUser.id && element.type === "partner") ||
            element.type === "entity"
          ) {
            this.AllUsers.push(element);
          }
        });
      },
    },
    recivedChats_k: {
      query: CHATS_ROOM_QUERY_KEN,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats_k) {
          this.loadMessages = true;
          console.log("chats_", data.recivedChats_k);
          this.chats = data.recivedChats_k.filter((e) => e.from === "Kenmare");
        }
      },
    },
    $subscribe: {
      addChat: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          console.log("chatkenmare notyf", data);
          if (data) {
            this.getMessage();
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          if (data) {
            this.getMessage();
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    username(username) {
      username = username.split(" ");
      return username[0] + " " + username[username.length - 1];
    },
    countReads(id) {
      var sum = 0;
      this.chats.forEach((element) => {
        if (element.room === id) {
          element.messages.forEach((msg) => {
            if (msg.read === "1" && msg.author.id === id) {
              sum = sum + 1;
            }
          });
        }
      });

      return sum;
    },

    initChat(user) {
      // this.chat = !this.chat;
      console.log(user);
      this.user = user;
      this.chats.forEach((element) => {
        if (element.room === user.id) {
          if (this.chat) {
            this.chat = true;
          } else {
            this.chat = true;
          }
        }
      });
    },
    close() {
      this.chat = false;
    },
    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            // this.chats = res.data.recivedChats;
            this.chats = res.data.recivedChats.filter(
              (e) => e.from === "Kenmare"
            );
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
